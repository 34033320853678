@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.chatbubble{
    background-color:rgba(223, 232, 233, 1)
}
.ruplychatbubble{
    background-color:rgb(188, 190, 190);
    color: black;
}
.banner-back{
    background-color: #4491DC;
}
.text-input {
    width: 100%;
    min-height: 50px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    resize: vertical;
  }
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }